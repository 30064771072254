import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import hmacSHA512 from "crypto-js/hmac-sha512";
import axios from "axios";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import logo from "../../../assets/logo.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaArrowLeft } from "react-icons/fa";
import emailjs from "@emailjs/browser";
import { FaRegCopy, FaRegCheckCircle } from "react-icons/fa";
import { BsBank } from "react-icons/bs";
const Bet = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [amount, setAmount] = useState("");
  const [bet, setBet] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const form = useRef();
  const navigate = useNavigate();
  const authHeader = useAuthHeader();

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch("https://swifttek.onrender.com/userinfo", {
          method: "GET",
          headers: {
            Authorization: authHeader,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUserDetails(data.user);
        } else {
          throw new Error("Failed to fetch user info");
        }
      } catch (error) {
        //console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, [authHeader]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsModalOpen(true);
  };

  const handleEmailSend = () => {
    const serviceId = "service_ez44cqc"; // Add your service ID
    const templateId = "template_tsdis8s"; // Add your template ID
    const publicKey = "MdFm5uJ70y58RIzYE"; // Add your public key

    emailjs
      .sendForm(serviceId, templateId, form.current, publicKey)
      .then(() => {
        console.log("SUCCESS!");
        setIsModalOpen(false);
      })
      .catch((error) => {
        console.log("FAILED...", error.text);
      });
    navigate("/dashboard/response");
  };

  const copyToClipboard = () => {
    const accountNumber = "6102193344"; // Example account number
    navigator.clipboard.writeText(accountNumber).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Show 'Copied!' for 2 seconds
    });
  };

  return (
    <div className="min-h-screen w-full flex flex-col items-center justify-center bg-blue-500">
      <div className="flex flex-row items-center justify-center gap-10 sm:ml-[30rem] ml-[5rem]  mb-6">
        <Link to="/dashboard" className="text-white">
          <FaArrowLeft />
        </Link>
        <div>
          <img src={logo} alt="Logo" className="w-[40%] sm:w-[20%]" />
        </div>
      </div>
      <div
        data-aos="zoom-in"
        data-aos-duration="1500"
        className="bg-white p-6 rounded-lg shadow-md w-full max-w-md flex flex-col items-center"
      >
        <h2 className="text-2xl font-bold mb-6 text-center">Bet Funding</h2>
        <form
          onSubmit={handleSubmit}
          className="w-full pt-6 pb-8 mb-4"
          ref={form}
        >
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="network"
            >
              Bet Provider
            </label>
            <select
              id="bet"
              value={bet}
              onChange={(e) => setBet(e.target.value)}
              name="network"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              <option value="">Choose a Provider</option>
              <option value="1XBET">1XBET</option>
              <option value="BETBONANZA">BETBONANZA</option>
              <option value="BETLION">BETLION</option>
              <option value="BANGBET">BANGBET</option>
              <option value="BET9JA">BET9JA</option>
              <option value="BETKING">BETKING</option>
              <option value="CLOUDBET">CLOUDBET</option>
              <option value="EASY WIN">EASY WIN</option>
              <option value="ILOT">ILOT</option>
              <option value="MELBET">MELBET</option>
              <option value="MYLOTTOHUB">MYLOTTOHUB</option>
              <option value="NAIJABET">NAIJABET</option>
              <option value="NAIRAMILLION">NAIRAMILLION</option>
              <option value="PARIMATCH">PARIMATCH</option>
              <option value="PARIPESA">PARIPESA</option>
            </select>
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="accountNumber"
            >
              Account Number
            </label>
            <input
              type="text"
              id="accountNumber"
              value={accountNumber}
              name="number"
              onChange={(e) => setAccountNumber(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="amount"
            >
              Customer Name
            </label>
            <input
              type="text"
              name="name"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              value={userDetails.email}
              readOnly
              placeholder={userDetails.email}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="amount"
            >
              Amount
            </label>
            <input
              type="number"
              id="amount"
              name="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              min="100"
              max="10000"
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-[#084DD6] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Pay Now
            </button>
          </div>
        </form>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-lg w-[80%] sm:w-[40%] flex items-center flex-col gap-3">
            <h3 className="text-xl font-bold mb-4 flex flex-row items-center justify-center gap-3 text-[#084DD6]">
              {" "}
              <span>
                <BsBank />
              </span>{" "}
              <span>Payment Details</span>
            </h3>
            <p>
              <strong>Account Name:</strong> SWIFTTEK ENTERPRISE
            </p>
            <p>
              <strong>Bank Name:</strong> OPAY
            </p>
            <div className="flex flex-row items-center justify-center gap-4">
              <span>
                <strong>Account Number:</strong>
                <span className="ml-2">
                  {copied ? "Copied!" : "6102193344"}
                </span>
                <button
                  className=" text-gray-700 font-bold ml-4"
                  onClick={copyToClipboard}
                >
                  {copied ? <FaRegCheckCircle /> : <FaRegCopy />}
                </button>
              </span>
            </div>

            <button
              className="bg-[#084DD6] hover:bg-blue-700 text-white font-bold py-2 px-4 mt-4 rounded ml-4"
              onClick={handleEmailSend}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Bet;
