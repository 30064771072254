import React, { useState, useEffect } from "react";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import logo from "../../assets/logo.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import "./dashboard.css";
import { EffectCards, Autoplay } from "swiper/modules";
import { BsLightbulb, BsPhone, BsWifi } from "react-icons/bs";
import { FaGraduationCap } from "react-icons/fa6";
import { PiSoccerBallFill, PiTelevision } from "react-icons/pi";
import { BsWhatsapp } from "react-icons/bs";
const Dashboard = () => {
  const authHeader = useAuthHeader();
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch("https://swifttek.onrender.com/userinfo", {
          method: "GET",
          headers: {
            Authorization: authHeader,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUserDetails(data.user);
        } else {
          throw new Error("Failed to fetch user info");
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, [authHeader]);
  return (
    <>
      {userDetails ? (
        <div className="overflow-x-hidden">
          <nav className="bg-[#084DD6] h-20 text-white px-[1rem] flex items-center justify-center">
            <div className="flex flex-row justify-between items-center max-w-app">
              <a href="/dashboard">
                <img
                  src={logo}
                  className="w-[30%] sm:w-[20%] md:w-[20%]"
                  alt=""
                />
              </a>
              <span className="text-sm sm:text-3xl flex flex-row gap-2">
                Welcome,{" "}
                <span>
                  <strong>{userDetails.firstName}</strong>
                </span>{" "}
              </span>
            </div>
          </nav>
          <div className="flex flex-col sm:flex-row items-center  justify-center h-screen gap-3 sm:gap-[8rem] px-[1.5rem] sm:px-[4rem]">
            <div>
              <Swiper
                effect={"cards"}
                grabCursor={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[EffectCards, Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide>Airtime/Data Top Up</SwiperSlide>
                <SwiperSlide>Electricity</SwiperSlide>
                <SwiperSlide>TV Subscription</SwiperSlide>
                <SwiperSlide>Education</SwiperSlide>
                <SwiperSlide>Bet Top Up</SwiperSlide>
              </Swiper>
            </div>

            <div className="flex flex-col gap-[2rem] sm:gap-[6rem] justify-center ">
              <div className="grid grid-flow-col grid-cols-3 gap-4 sm:gap-12 justify-center max-w-app">
                <Link to="/dashboard/topup" className="flex flex-col gap-4 items-center">
                  <div className="hero-serv hover:animate-pulse cursor-pointer text-xl">
                    <BsPhone />
                  </div>{" "}
                  <span className="text-sm sm:text-xl">Airtime</span>
                </Link>
                <Link to="/dashboard/data" className="flex flex-col gap-4 items-center">
                  <div className="hero-serv hover:animate-pulse cursor-pointer text-xl">
                    <BsWifi />
                  </div>{" "}
                  <span className="text-sm sm:text-xl" >Data</span>
                </Link>
                <Link to="/dashboard/education" className="flex flex-col gap-4 items-center">
                  <div className="hero-serv hover:animate-pulse cursor-pointer text-xl">
                    <FaGraduationCap />
                  </div>{" "}
                  <span className="text-sm sm:text-xl">Education </span>
                </Link>
              </div>
              <div className="grid grid-flow-col grid-cols-3 gap-4 sm:gap-12 justify-center ">
                <Link to="/dashboard/subscription" className="flex flex-col gap-4 items-center">
                  <div className="hero-serv hover:animate-pulse cursor-pointer text-xl">
                    <PiTelevision />
                  </div>{" "}
                  <span className="text-sm sm:text-xl">Cable TV</span>
                </Link>
                <Link to="/dashboard/electricity" className="flex flex-col gap-4 items-center">
                  <div className="hero-serv hover:animate-pulse cursor-pointer text-xl">
                    <BsLightbulb />
                  </div>{" "}
                  <span className="text-sm sm:text-xl">Electricity</span>
                </Link>
                <Link to="/dashboard/bet" className="flex flex-col gap-4 items-center">
                  <div className="hero-serv hover:animate-pulse cursor-pointer text-xl">
                    <PiSoccerBallFill />
                  </div>{" "}
                  <span className="text-sm sm:text-xl">Bet Top Up</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="fixed bottom-2 right-0 px-5 py-2 rounded-full bg-green-500 cursor-pointer text-white">
        <a href="https://wa.me/+2348106976721?text=Hello I would love if you can assist me with ____" target="_blank" rel="noopener noreferrer" className="flex flex-row items-center justify-center gap-2">
          {/* <img src={whatsapp} alt="" className="w-1/2"/> */}
          <span><BsWhatsapp /></span> <span>Chat with us!</span>
        </a>
      </div>
          {/* Render other user details as needed */}
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen">
          <div className="w-28 h-28 border-8 text-blue-400 text-4xl animate-spin border-gray-300 flex items-center justify-center border-t-blue-400 rounded-full">
            <svg
              viewBox="0 0 24 24"
              fill="currentColor"
              height="1em"
              width="1em"
              className="animate-ping"
            >
              <path d="M12.001 4.8c-3.2 0-5.2 1.6-6 4.8 1.2-1.6 2.6-2.2 4.2-1.8.913.228 1.565.89 2.288 1.624C13.666 10.618 15.027 12 18.001 12c3.2 0 5.2-1.6 6-4.8-1.2 1.6-2.6 2.2-4.2 1.8-.913-.228-1.565-.89-2.288-1.624C16.337 6.182 14.976 4.8 12.001 4.8zm-6 7.2c-3.2 0-5.2 1.6-6 4.8 1.2-1.6 2.6-2.2 4.2-1.8.913.228 1.565.89 2.288 1.624 1.177 1.194 2.538 2.576 5.512 2.576 3.2 0 5.2-1.6 6-4.8-1.2 1.6-2.6 2.2-4.2 1.8-.913-.228-1.565-.89-2.288-1.624C10.337 13.382 8.976 12 6.001 12z"></path>
            </svg>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
