import React from 'react';
import checkGif from '../../../assets/check.gif'; // Make sure to import your green check GIF here

const Response = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-lg w-full">
        <img 
          src={checkGif} 
          alt="Success Check" 
          className="w-20 h-20 mx-auto mb-6"
        />
        <h1 className="text-2xl font-bold text-green-600 mb-4">
          Thank you for placing an order with us!
        </h1>
        <p className="text-gray-600">
          We will get in touch with you as soon as your payment is received.
        </p>
      </div>
    </div>
  );
};

export default Response;
