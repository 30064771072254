
import { BrowserRouter as Router, Routes, Route  } from "react-router-dom";
import AuthOutlet from '@auth-kit/react-router/AuthOutlet'
import Home from "./pages/landing/home/Home";
import Login from "./pages/landing/login/Login";
import Register from "./pages/landing/register/Register";
import Dashboard from "./pages/dashboard/Dashboard";
import Response from "./pages/dashboard/airtime/Response";
import Topup from "./pages/dashboard/airtime/Topup";
import Education from "./pages/dashboard/edusub/Education";
import EducationReceipt from "./pages/dashboard/edusub/EducationReceipt";
import Waec from "./pages/dashboard/edusub/Waec";
import Jamb from "./pages/dashboard/edusub/Jamb";
import Neco from "./pages/dashboard/edusub/Neco";
import Nabteb from "./pages/dashboard/edusub/Nabteb"
import Bet from "./pages/dashboard/betsub/Bet";
import Electricity from "./pages/dashboard/electsub/Electricity";
import ElectricityReciept from "./pages/dashboard/electsub/ElectricityReciept";
import Payment from "./pages/dashboard/Payment";
import Subscription from "./pages/dashboard/cablesub/Subscription";
import SubReciept from "./pages/dashboard/cablesub/SubReciept";
import Data from "./pages/dashboard/datasub/Data";
import ResetPassword from "./pages/landing/login/ResetPassword";


function App() {
  return (
    <div>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/reset" element={<ResetPassword />} />
         <Route element={<AuthOutlet fallbackPath='/login' />} > 
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/bet"  element= {<Bet />} />
          <Route path="/dashboard/data" element= {<Data />} />
          <Route path="/dashboard/topup" element= {<Topup />} />
          <Route path="/dashboard/response" element = {<Response />} />
          <Route path="/dashboard/electricity" element = {<Electricity />} />     
          <Route path="/dashboard/education" element = {<Education />} />
          <Route path="/dashboard/education/waec" element={<Waec />} />
          <Route path="/dashboard/education/jamb" element={<Jamb />} />
          <Route path="/dashboard/education/neco" element={<Neco />} />
          <Route path="/dashboard/education/nabteb" element={<Nabteb />} />
          <Route path="/dashboard/subscription" element = {<Subscription />} />
         </Route> 
      </Routes>
    </Router>
    </div>
  );
}

export default App;
